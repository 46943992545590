
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.edit-approval {
  padding-bottom: 80px;
  &__header {
    margin-bottom: $base-gutter;
  }
  &__settings {
    background-color: $color-white;
    padding: $base-gutter * 1.7;
    border-radius: $base-border-radius * 2.5;
  }
  &__setting {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: $base-gutter;
    margin-bottom: $base-gutter;
  }
  &__setting-name {
    width: 350px;
    text-align: right;
    color: $color-grayscale-60;
  }
  &__setting-field {
    width: 50%;
  }
}
